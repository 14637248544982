import * as React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import Image from "../components/image"

const BookBurnerPage = () => {
  return (
    <Layout title="The Book-Burner">
        <h2>Field Notes</h2>
        <center><Image src="bookburner-2.png" alt="Drawing" /></center>
        <p>The BOOK-BURNER is a trickster GOD on the world of her origin.</p>
        <ul>
          <li>The BOOK-BURNER has eyes that mimic the cosmos. The pupil of one eye resembles the moon in its proper phase, glowing when the moon is full and dimming as it wanes.</li>
          <li>She lives on a rocky beach, hidden. I had great difficulty seeking her out.</li>
          <li>Her enemy is <Link to="../librarian">the LIBRARIAN</Link>, though there appears to be far more to their relationship...</li>
          <li>She steals record-seeds from the LIBRARIAN's eyes and burns them. Once burnt, the information in the records is lost forever.</li>
        </ul>
    </Layout>
  )
}

export default BookBurnerPage